import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { getHighlight } from '../../api/api';
import { urlWithQueryParams } from '../../api/helpers';
import { formatDate } from '../../util/format';
import { getQueryParam } from '../../util/url';

import HighlightHeader from '../HighlightHeader/HighlightHeader';
import ShareIcon from '../ShareIcon/ShareIcon';
import TranscriptRoll from '../TranscriptRoll/TranscriptRoll';

import { Snippet } from '../../types/conversation';
import styles from './HighlightCard.module.scss';
import GlobalAudioContext from '../../contexts/GlobalAudioContext';
import AudioControls from '../AudioControls/AudioControls';
import WhatIsThisView from '../WhatIsThisView/WhatIsThisView';
import SignUpView from '../SignUpView/SignUpView';
import ShareView from '../ShareView/ShareView';
import { getActiveSnippet } from '../../util/snippets';
import NotFoundView from '../NotFoundView/NotFoundView';
import StylelessButton from '../StylelessButton/StylelessButton';
import MinimalCard from '../MinimalCard/MinimalCard';
import { Action, Name } from '../../types/analytics';

interface Highlight {
  id: number;
  location: string;
  audioUrl: string;
  host: string;
  date: Date;
  snippets: Snippet[];
  collection: string;
  organization: string;
  audio_start_offset: number;
  audio_end_offset: number;
  fade_in_out: number;
}

type ActiveView = 'highlight' | 'what-is-this' | 'sign-up' | 'share';

const getAbbreviatedHostName = (fullHostName: string) => {
  const [first, last] = fullHostName.split(' ');
  // if we couldn't separate into first/last name, just return the full name
  if (!first || !last) {
    return fullHostName;
  }
  return `${first} ${last[0]}.`;
};

const breakPoint = 475;

const HighlightCard = () => {
  const [highlight, setHighlight] = React.useState<Highlight>();
  const [activeView, setActiveView] = React.useState<ActiveView>('highlight');
  const [highlightIsLoading, setHighlightIsLoading] = React.useState(true);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [autoShowNextView, setAutoShowNextView] = React.useState(true);
  const [minimal, setMinimal] = React.useState<boolean>(false);

  React.useEffect(() => {
    const highlightIdParam = getQueryParam('hid');
    const secretParam = getQueryParam('secret');
    const typeParam = getQueryParam('type');
    setMinimal(typeParam === 'minimal');
    const highlightId = highlightIdParam && parseInt(highlightIdParam);
    if (highlightId) {
      getHighlight(highlightId, secretParam)
        .then((data) => {
          const highlight = {
            id: data.annotation.id,
            location: data.conversation.location.name,
            audioUrl: urlWithQueryParams(
              `/highlight/${highlightId}/play`,
              secretParam ? { secret: secretParam } : undefined
            ),
            collection: data.collection_name,
            organization: data.organization_name,
            host: data.host_name,
            date: data.conversation.start_time,
            audio_start_offset: data.annotation.audio_start_offset,
            audio_end_offset: data.annotation.audio_end_offset,
            snippets: data.snippets,
            fade_in_out:
              data.annotation.audio_fade_in_out != null
                ? data.annotation.audio_fade_in_out
                : 1, // default to 1 if unavailable
          };
          setHighlight(highlight);
          setHighlightIsLoading(false);
        })
        .catch(() => {
          setHighlightIsLoading(false);
        });
    } else {
      setHighlightIsLoading(false);
    }
    // add a listener for window resizing
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const windowSize = windowWidth > breakPoint ? 'lg' : 'sm';
  const handleChangeView = (view: ActiveView, eventType?: string) => {
    let action = eventType === 'exit' ? Action.Exit : Action.Click;
    let name = Name.Highlight;

    if (view === 'what-is-this') {
      name = Name.WhatIsThis;
    } else if (view === 'sign-up') {
      name = Name.SignUp;
    } else if (view === 'share') {
      name = Name.Share;
    }

    setActiveView(view);
  };

  const {
    isLoading,
    isLoaded,
    seekTime,
    duration,
    onPlay,
    onSeek,
    audioError,
  } = React.useContext(GlobalAudioContext);
  const isEndOfAudio = seekTime === duration && seekTime != null;
  const isHighlightView = activeView === 'highlight';

  React.useEffect(() => {
    // check if we are at the end of the clip
    if (isEndOfAudio && autoShowNextView) {
      // wait a few seconds, then show the sign up view
      const timer = setTimeout(() => {
        // if the user changed views before the time out, keep them on that view
        const endCardParamValue = getQueryParam('endcard');
        const showEmbedEndCard = !(
          endCardParamValue === 'false' ||
          endCardParamValue === '0' ||
          endCardParamValue === ''
        );
        if (isHighlightView && showEmbedEndCard) {
          setActiveView('sign-up');
        } else {
          onSeek(0);
        }
        setAutoShowNextView(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isEndOfAudio, isHighlightView, autoShowNextView, onSeek]);

  const handleReplay = () => {
    // sets the active view back to the highlight and autoplays
    setActiveView('highlight');
    onPlay(0);
  };

  if (highlightIsLoading) {
    return null;
  }

  if (!highlight) {
    return (
      <div className={styles.highlightCard} data-testid="highlight-card">
        <NotFoundView
          onWhatIsThisClick={() => handleChangeView('what-is-this')}
        />
        <CSSTransition
          in={activeView === 'what-is-this'}
          timeout={500}
          unmountOnExit
          classNames={{
            enterActive: styles.slideInUp,
            exitActive: styles.slideOutDown,
          }}
        >
          <div className={`${styles.overlay} ${styles.animated}`}>
            <WhatIsThisView
              onExit={() => handleChangeView('highlight', 'exit')}
              small={windowSize === 'sm'}
            />
          </div>
        </CSSTransition>
      </div>
    );
  }

  // determine the speaker based on the seektime
  // we get snippet offset from the original transcript, but our audio file is the length of the highlight only
  // so adjust the seek time to add the start time of the highlight
  const adjustedSeekTime = seekTime
    ? seekTime + highlight.audio_start_offset - highlight.fade_in_out
    : undefined;
  const activeSnippet = getActiveSnippet(
    adjustedSeekTime,
    highlight.snippets,
    highlight.audio_start_offset
  );

  // set speaker to the first speaker
  let speaker = '';
  if (activeSnippet) {
    speaker = activeSnippet.speaker_name;
  }

  // IE has trouble with getting duration from audio, so use this as a fallback
  // when IE has trouble, duration = Infinity
  const fallbackDuration =
    highlight.audio_end_offset -
    highlight.audio_start_offset +
    highlight.fade_in_out * 2;

  if (minimal) {
    return (
      <MinimalCard
        audioUrl={highlight.audioUrl}
        seekTime={adjustedSeekTime}
        snippets={highlight.snippets}
        activeSnippet={activeSnippet}
        startTime={highlight.audio_start_offset}
        endTime={highlight.audio_end_offset}
        windowWidth={windowWidth}
        onSeek={onSeek}
        fadeInTime={highlight.fade_in_out}
      />
    );
  }

  return (
    <div className={styles.highlightCard} data-testid="highlight-card">
      <div>
        <AudioControls
          className={styles.audioControls}
          showPlayControls={false}
          isLoading={isLoading}
          isLoaded={isLoaded && audioError == null}
          seekTime={seekTime}
          duration={
            duration === Number.POSITIVE_INFINITY ? fallbackDuration : duration
          }
          onSeek={onSeek}
        />
        <HighlightHeader
          speaker={speaker}
          location={highlight.location}
          collection={highlight.collection}
          audioUrl={highlight.audioUrl}
          onLogoClick={() => handleChangeView('sign-up')}
          small={windowSize === 'sm'}
        />
      </div>
      <div className={styles.transcript}>
        <TranscriptRoll
          seekTime={adjustedSeekTime}
          snippets={highlight.snippets}
          activeSnippet={activeSnippet}
          startTime={highlight.audio_start_offset}
          endTime={highlight.audio_end_offset}
          windowWidth={windowWidth}
          onSeek={onSeek}
          fadeInTime={highlight.fade_in_out}
          audioIsLoaded={isLoaded && audioError == null}
        />
      </div>
      <div className={styles.footer}>
        <StylelessButton
          className={styles.clickable}
          onClick={() => handleChangeView('share')}
          data-testid="share-button"
        >
          <ShareIcon width={9} />
          {windowSize === 'lg' && (
            <span className={styles.shareText}>Share</span>
          )}
        </StylelessButton>
        <span
          className={styles.hostInfo}
          title={`${highlight.host} · ${formatDate(highlight.date)}`}
        >
          Hosted by{' '}
          {windowSize === 'lg'
            ? highlight.host
            : getAbbreviatedHostName(highlight.host)}{' '}
          <span className={styles.footerSeparator}>·</span>{' '}
          {formatDate(highlight.date)}{' '}
        </span>
        <StylelessButton
          onClick={() => handleChangeView('what-is-this')}
          className={styles.clickable}
          data-testid="what-is-this-button"
        >
          {windowSize === 'lg' ? 'What is this?' : '?'}{' '}
        </StylelessButton>
      </div>
      <CSSTransition
        in={activeView === 'what-is-this'}
        timeout={500}
        unmountOnExit
        classNames={{
          enterActive: styles.slideInUp,
          exitActive: styles.slideOutDown,
        }}
      >
        <div
          className={`${styles.overlay} ${styles.animated}`}
          data-testid="what-is-this"
        >
          <WhatIsThisView
            organization={highlight.organization}
            collection={highlight.collection}
            onExit={() => handleChangeView('highlight', 'exit')}
            small={windowSize === 'sm'}
          />
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeView === 'sign-up'}
        timeout={500}
        unmountOnExit
        classNames={{
          enterActive: styles.slideInRight,
          exitActive: styles.slideOutRight,
        }}
      >
        <div
          className={`${styles.overlay} ${styles.animated}`}
          data-testid="sign-up"
        >
          <SignUpView
            speaker={speaker}
            location={highlight.location}
            showPlayAgainButton={isEndOfAudio}
            onReplay={handleReplay}
            onExit={() => handleChangeView('highlight', 'exit')}
            small={windowSize === 'sm'}
          />
        </div>
      </CSSTransition>
      <CSSTransition
        in={activeView === 'share'}
        timeout={500}
        unmountOnExit
        classNames={{
          enterActive: styles.slideInUp,
          exitActive: styles.slideOutDown,
        }}
      >
        <div
          className={`${styles.overlay} ${styles.animated}`}
          data-testid="share"
        >
          <ShareView
            highlightId={highlight.id}
            onExit={() => handleChangeView('highlight', 'exit')}
          />
        </div>
      </CSSTransition>
    </div>
  );
};

export default HighlightCard;
